@import 'styles/flex-mixins';
@import 'styles/media';

.accountant_page {
    @include column(flex-start, flex-start, 4vmin);

    @include landscape-max-width {
        align-items: center;

        text-align: center;
    }
    padding: 6vmin 7vw;

    .page_title {
        font-family: MontserratBold, serif;
        font-size: clamp(28px, 9vw, 35px);

    }

    .page_container {
        @include row(space-between, flex-start, 5vmin);

        @include laptop-s-max-width {
            width: 100%;
        }

        @include landscape-max-width {
            @include column-reverse(center, flex-start, 5vmin);
        }

        width: 100%;

        .users_list {
            @include column(flex-start, flex-start, 16px);
            @include landscape-max-width {
                width: 100%;
            }

            width: 55%;

            .user_card {
                @include row(flex-start, center, 10px);

                width: 100%;
                height: 65px;
                padding: 8px;

                background-color: var(--neutral-10);
                border: 2px solid transparent;
                border-radius: var(--default-border-radius);

                &--active {
                    border-color: var(--primary-01);
                }

                .avatar {
                    height: 100%;
                }

                .username {
                    color: var(--neutral-00);
                }

                .empty_block {
                    flex-grow: 1;
                }

                .money {
                    justify-self: end;

                    font-family: MontserratBold, serif;
                    color: var(--primary-01-dark);

                    &::after {
                        content: '\20BD';

                        margin-left: 5px;
                    }
                }
            }
        }

        .actions {
            @include landscape-max-width {
                width: 80%;
            }

            @include tablet-max-width {
                width: 100%;
            }

            width: 40%;

            .replenishment_amount_form {
                @include row(flex-start, flex-start, 15px);

                width: 100%;
                margin-top: 2vmin;
                padding: 4%;

                background-color: var(--neutral-10);

                .replenishment_amount {
                    padding-left: 50px;

                    background: url('/assets/balance/ruble.svg') no-repeat 15px 12px var(--neutral-10);
                }

                .btn {
                    width: 40%;
                    min-width: 110px;
                }
            }
        }
    }
}

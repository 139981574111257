@import 'styles/flex-mixins';

.modal_content {
    z-index: 10;

    width: 450px;
    height: max-content;
    min-height: 150px;
    padding: 0.75rem 1.25rem;

    background-color: var(--neutral-08);
    border-radius: var(--default-border-radius);

    .modal_title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 10px;
        padding: 0.75rem 0;

        // border-bottom: 1px solid var(--neutral-08);
    }

    .modal_body {
        @include column(flex-start, flex-start, 10px);

        .modal_actions {
            @include row(end, center, 10px);
            width: 100%;
            margin-top: 30px;

            .btn {
                min-width: 120px;
                min-height: 40px;
            }
        }
    }
}

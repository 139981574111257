@import 'styles/flex-mixins';
@import 'styles/media';

span:fullscreen img {
    object-fit: contain;
    border: 0;
}

span:fullscreen {
    overflow: auto;
}

span::backdrop {
    visibility: hidden;
}

.result_container {
    @include row(space-between, flex-start, clamp(20px, 5vw, 65px));

    width: 100%;

    .result_tip {
        font-size: 1.15rem;
    }

    .result_image {
        cursor: pointer;

        width: 300px;
        height: 340px;

        span {
            width: inherit;
            height: inherit;
        }

        img {
            width: inherit;
            height: inherit;
        }
    }

    .result_actions {
        @include column(flex-start, flex-start, 20px);

        .btn {
            width: 200px;
        }
    }
}


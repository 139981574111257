@import 'styles/flex-mixins';
@import 'styles/media';
@import 'styles/generics';

%default-card {
    width: 100%;

    background: var(--neutral-10);
    border-radius: var(--default-border-radius);
    box-shadow: 0 10px 35px var(--shadow-1);
}

%active-card {
    background-color: var(--primary-01);

    &::after {
        background-color: var(--neutral-10);
    }

    > * {
        color: var(--neutral-10);
    }
}

.records_list {
    @include column(center, center, 20px);
    width: 100%;

    .records_count {
        width: 100%;
        margin-bottom: 16px;
        padding-left: 10px;

        color: var(--neutral-04);
    }

    .record_item {
        @extend %default-card;
        cursor: pointer;

        &__header {
            position: relative;

            display: flex;
            flex-direction: row;
            align-items: center;

            height: 40px;
            padding: 10px;

            background: var(--primary-01-light);
            border-top-left-radius: var(--default-border-radius);
            border-top-right-radius: var(--default-border-radius);

            transition: background-color 0.2s;

            &::after {
                content: '';

                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);

                display: inline-block;

                width: 12px;
                height: 8px;

                background-color: var(--primary-01-dark);

                mask: url('/assets/arrow-without-body.svg');

                transition: transform 0.2s ease-in-out;
            }

            &:hover {
                @extend %active-card;
            }

            > * {
                color: var(--primary-01-dark);
            }

            .date {
                margin-right: 2%;

                font-family: MontserratBold, serif;
            }

            .time {
                margin-right: 5%;
            }

            .folder {
                margin-right: 7%;
                margin-left: auto;
            }
        }

        &__body {
            display: none;

            padding: 0 20px 20px;

            .record_actions {
                @include row(flex-start, center, 10px);

                .download_info {
                    color: var(--neutral-02);
                }

                .download_text,
                .download_record {
                    min-width: 115px;
                }

                .delete_record {
                    cursor: pointer;

                    margin-left: auto;

                    color: var(--neutral-05);

                    &:hover {
                        color: var(--service-01);
                    }
                }
            }

            .player {
                display: flex;

                width: 100%;
                margin-bottom: 20px;
            }
        }

        &__input {
            display: none;

            &:checked ~ .record_item__header {
                @extend %active-card;

                &::after {
                    transform: rotate(-180deg) translateY(50%);
                }
            }

            &:checked ~ .clickable_area > .record_item__body {
                display: block;
            }
        }

        &__title {
            display: block;

            margin-bottom: 10px;

            .highlighted {
                background-color: var(--primary-02-light);
            }
        }

        &__text {
            color: var(--neutral-04);

            .highlighted {
                background-color: var(--primary-02-light);
            }
        }
    }

    .active_download_item {
        @extend %default-card;
        @include column(flex-start, center, 10px);
        min-height: 100px;
        padding: 15px 24px;

        .progress_wrapper {
            @include column(flex-start, center, 5px);
            width: 100%;
            margin-bottom: 8px;

            .percent_actions_wrapper {
                @include row(space-between, center, 5px);
                width: 100%;
                margin-bottom: 10px;

                .cancel {
                    cursor: pointer;

                    margin-left: auto;

                    font-family: MontserratBold, serif;
                    font-size: 0.8rem;
                    color: var(--service-01);
                }
            }
        }

        .failed_wrapper {
            @include row(space-between, center, 5px);
            width: 100%;
            margin-bottom: 10px;

            color: var(--service-01);

            .hide {
                cursor: pointer;

                margin-left: auto;

                font-family: MontserratBold, serif;
                font-size: 0.8rem;
            }
        }

        .title {
            font-size: 0.85rem;
            color: var(--neutral-02);
        }
    }

    .limiter_line {
        display: flex;
        flex-direction: row;

        width: 100%;
        margin: 10px auto;

        color: var(--neutral-05);

        &::before,
        &::after {
            content: '';

            flex: 1 1;

            margin: auto;

            border-bottom: 1px solid var(--neutral-05);
        }

        &:not(:empty)::before {
            margin-right: 10px;
        }

        &:not(:empty)::after {
            margin-left: 10px;
        }
    }
}

@import 'styles/media';

.transcript_page {
    overflow-y: scroll;

    height: 100%;
    max-height: calc(100vh - var(--header-height) - var(--header-top-margin));
    padding-top: 3vh;

    .records_page_container {
        overflow-y: unset;

        height: unset;
    }

    .transcript_page_header {
        .back_arrow {
            cursor: pointer;

            margin: 0 var(--side-margin) 24px;

            color: var(--neutral-02);

            &::before {
                content: url('/assets/arrow-without-body.svg');

                transform: rotate(90deg);

                display: inline-block;

                margin: 0 10px;

                filter: opacity(0.6);
            }
        }

        .wrapper {
            &::after {
                content: url('/assets/pen-in-square.svg');

                width: 15px;
                margin-left: 15px;

                visibility: hidden;
            }

            &:hover::after {
                visibility: visible;
            }
        }

        .page_title {
            cursor: pointer;

            width: fit-content;
            padding: 5px 15px;

            border: 1px solid transparent;
            border-radius: var(--default-border-radius);

            &::after {
                content: url('/assets/pen-in-square.svg');

                width: 15px;
                margin-left: 15px;

                visibility: hidden;
            }

            &:focus {
                border: 1px solid var(--primary-01);
            }

            &:hover::after {
                visibility: visible;
            }
        }
    }

    .page_content {
        overflow-y: unset;
        justify-content: center;
    }

    .section_left {
        @include laptop-s-max-width {
            max-width: 100%;
        }

        flex: 1;

        max-width: 70%;
    }

    .section_right:not(:empty) {
        @include laptop-s-min-width {
            width: 5%;
            min-width: fit-content;

            &:has(.opening) {
                width: 35%;
                min-width: initial;

                transition: width 0.4s ease-out;
            }
        }

        #show-comments {
            display: none;
        }

        .open_icon {
            cursor: pointer;

            width: 25px;
            height: auto;
        }
    }
}

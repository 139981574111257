.replica__word {
    color: var(--gray-170);
    overflow-wrap: anywhere;
    white-space: pre-wrap;

    &--highlighted {
        color: var(--primary-01);

        outline: none;
    }

    &--searched {
        background-color: var(--orange);
        outline: none;
    }
}

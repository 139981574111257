@import 'styles/flex-mixins';
@import 'styles/generics';

.search_bar_wrapper {
    width: 100%;
    padding: 12px;


    background-color: var(--primary-01-light);
    border-radius: var(--default-border-radius);
    box-shadow: 0 2px 35px var(--shadow-1);
}

.search_bar {
    @include row(space-between, center, 10px);

    overflow: hidden;

    width: 100%;
    height: 46px;
    padding: 15px;

    background-color: var(--neutral-10);
    border: 3px solid var(--primary-01-light);
    border-radius: var(--default-border-radius);

    &::placeholder {
        color: var(--neutral-05);
    }

    &:focus-within {
        border-color: var(--primary-01);
    }

    .clear_icon {
        cursor: pointer;

        width: 20px;
        height: 20px;
        margin-left: 11px;

        color: var(--neutral-04);

        &:hover {
            color: var(--neutral-02);
        }
    }

    .search_input {
        flex-grow: 1;

        border: none;
        outline: none;
    }

    .search_icon {
        min-width: 1em;
    }
}

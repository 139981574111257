@import 'styles/flex-mixins';
@import 'styles/media';
@import 'styles/mixins';

.adding_speaker_form {
    width: 100%;

    .btn {
        width: 100%;
        margin: 20px 0 10px;
    }
}

.speakers_page {
    padding: 6vmin 7vw;

    .page_title {
        margin-bottom: 40px;

        font-family: MontserratBold, serif;
        font-size: clamp(28px, 9vw, 35px);
    }

    .search_and_add_line {
        @include row(space-between, center, 20px);

        .search_bar {
            max-width: 580px;
        }

        .btn {
            width: 180px;
        }
    }

    .speakers_list {
        @include column(center, center, 5px);
        max-width: 700px;
        margin-top: 20px;

        .speaker_card {
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 100%;
            height: 65px;

            .info {
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: space-between;

                height: 100%;
                margin-right: 30px;
                padding: 0 10px;

                border-bottom: 2px solid var(--primary-01-light);

                .name {
                    font-family: MontserratBold, serif;
                }
            }

            .count {
                color: var(--primary-01-dark);
            }

            .actions {
                position: relative;

                display: flex;
                align-items: center;

                .icon {
                    @include transition(color 0.5s);

                    color: var(--neutral-07);

                    &:hover {
                        color: var(--primary-01);
                    }

                    &:not(:last-of-type) {
                        margin-right: 20px;
                    }
                }

                .modal_content {
                    position: absolute;
                    top: 0;
                    right: 30px;

                    width: 330px;

                    background-color: white;
                    border-radius: 20px;
                    box-shadow: 0 2px 70px #0000000d;

                    .editing_speaker_form {
                        width: 100%;

                        .input_wrapper {
                            margin-bottom: 20px;
                        }

                        .btn {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

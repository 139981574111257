::-webkit-scrollbar-button {
    width: var(--scroll-width);
    height: 0;

    background-repeat: no-repeat;
}

::-webkit-scrollbar-track {
    background-color: var(--primary-scroll-background);
}

::-webkit-scrollbar-thumb {
    background-color: var(--primary-01-light);
    border-radius: 0;
}

::-webkit-resizer {
    width: 4px;
    height: 0;

    background-repeat: no-repeat;
}

::-webkit-scrollbar {
    width: var(--scroll-width);
}

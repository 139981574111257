@import 'styles/flex-mixins';
@import 'styles/media';

.top_up_container {
    width: 100%;

    .bank_cards_container {
        @include column(flex-start, flex-start, 12px);
        width: 100%;

        .bank_card {
            @include row(flex-start, center, 20px);

            width: 100%;
            min-height: 90px;
            padding: 2% 4%;

            background: var(--neutral-10);
            border: 2px solid transparent;
            border-radius: var(--default-border-radius);

            &--active {
                border-color: var(--primary-01);
            }

            &__logo {
                width: 60px;
                height: 40px;
            }

            &__info {
                @include column(flex-start, center, 5px);

                .title {
                    font-family: MontserratBold, serif;
                }

                .validity_period {
                    font-size: 0.93rem;
                    line-height: 1.14rem;
                }
            }
        }
    }

    .payment_new_card {
        @include row(flex-start, center, 5px);
        cursor: pointer;

        margin-top: 3vmin;
    }

    .new_card_container {
        @include column(flex-start, space-around, 10px);

        width: 100%;
        min-height: 130px;
        margin-top: 2vmin;
        padding: 2% 4%;

        background-color: var(--neutral-08);
        border-radius: var(--default-border-radius);

        .new_card_form {
            @include row(space-between, center, 12px);

            .input {
                width: initial;
                height: 30px;

                border: none;
                outline: none;
            }

            .date {
                @include row(center, center, 0.15rem);

                > .input {
                    overflow: hidden;

                    width: 2rem;
                    padding-right: 0;
                    padding-left: 0;
                }

                > .input:first-child {
                    text-align: end;
                }
            }

            .cvc {
                overflow: hidden;

                width: 4.2rem;
            }
        }

        .toggle_container {
            @include row(flex-start, center, 15px);

            > p {
                font-family: MontserratSemibold, serif;
            }
        }
    }

    .deposit_amount_form {
        @include row(flex-start, flex-start, 15px);

        width: 100%;
        margin-top: 2vmin;
        padding: 4%;

        background-color: var(--neutral-10);

        .deposit_amount {
            padding-left: 50px;

            background: url('/assets/balance/ruble.svg') no-repeat 15px 12px var(--neutral-10);
        }

        > * {
            flex: 1;
        }
    }
}

@import 'styles/flex-mixins';
@import 'styles/media';

.search_filter {
    @include column(center, center, 2vh);

    width: 100%;
    max-width: 370px;
    margin-left: auto;

    .search_bar {
        width: 100%;
    }
}

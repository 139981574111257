@import 'styles/flex-mixins';
@import 'styles/media';

$margin: 5vmin;

.profile_receipts {
    @include column(flex-start, center, $margin);
    @include tablet-max-width {
        width: 100%;
    }

    &_title {
       font-family: MontserratBold, serif;
       font-size: 36px;
       line-height: 44px;
       color: var(--neutral-00);
    }

    &_info {
       font-family: MontserratRegular, serif;
    }

    &_form {
        @include column(flex-start, center, $margin);

        @include tablet-max-width {
            width: 100%;
        }

        width: 360px;

        .btn {
            width: 100%;
            margin-top: calc($margin * 1.2);
        }
    }
}

@import 'styles/flex-mixins';

.calendar {
    .calendar_navigation {
        @include column(center, center, 10px);

        .line_container {
            @include row(space-between, center, 5px);
            width: 100%;
        }
    }

    .field_container {
        position: relative;
    }

    .field,
    .field_container {
        flex: 1;
    }

    .field,
    .field--selected,
    .navigation_button {
        cursor: pointer;

        width: 100%;
        height: 36px;
        padding: 5px 10px;

        color: var(--neutral-04);

        background-color: var(--neutral-08);
        border: none;
        border-radius: var(--default-border-radius);
    }

    .field--selected {
        color: var(--neutral-10);

        background-color: var(--primary-01);
    }

    .disabled_button {
        color: var(--neutral-05);
    }

    .month_navigation {
        @include row(space-between, center, 3%);

        width: 70%;
        height: 36px;

        .navigation_button {
            width: 19%;

            .arrow {
                &--left {
                    transform: rotate(90deg);
                }

                &--right {
                    transform: rotate(-90deg);
                }
            }
        }
    }

    .choose_mode {
        display: flex;

        width: 70%;
        height: 36px;

        background-color: var(--neutral-08);
        border-radius: var(--default-border-radius);

        button {
            flex: 1;
        }
    }

    .dropdown_list {
        position: absolute;
        z-index: 5;

        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        width: 100%;
        max-height: 150px;
        margin-top: 3px;
        padding: 0;

        list-style: none;

        background-color: var(--neutral-10);
        border: 1px solid var(--neutral-08);
        border-radius: 4px;
    }

    .dropdown_list::-webkit-scrollbar {
        width: 8px;
    }

    .dropdown_list::-webkit-scrollbar-track {
        background-color: var(--neutral-08);
    }

    .dropdown_list::-webkit-scrollbar-thumb {
        background-color: var(--neutral-05);
        border-radius: 4px;
    }

    .dropdown_list_item {
        cursor: pointer;

        width: 100%;
        height: 25px;
        padding: 3px;

        color: var(--neutral-04);
        text-align: center;
    }

    .dropdown_list_item:hover,
    .selected_item {
        background-color: var(--neutral-08);
    }
}

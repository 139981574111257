@import 'styles/flex-mixins';
@import 'styles/mixins';

.speakers_dropdown {
    display: flex;
    flex-wrap: wrap;

    &__header {
        cursor: pointer;
        user-select: none;

        display: flex;
        justify-content: space-between;

        svg {
            @include transition(fill 0.5s);
            fill: var(--neutral-08);
        }

        &__title {
            font-family: MontserratBold, serif;
        }
    }

    &__content {
        position: absolute;
        z-index: 5;

        display: flex;
        flex-direction: column;

        width: 240px;
        margin-top: 25px;
        padding: 20px;

        background-color: white;
        border-radius: 20px;
        box-shadow: 0 2px 70px var(--shadow-1);

        &__header {
            align-self: center;

            margin-bottom: 20px;

            font-family: MontserratSemibold, sans-serif;
            font-size: 1.125rem;
        }

        &__list {
            overflow-y: auto;

            margin-bottom: 20px;

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;

                width: 100%;
                height: 25px;
                padding: 1rem;

                border-radius: 8px;

                &:hover,
                &:focus {
                    background-color: var(--neutral-09);
                }
            }
        }
    }
}

.choose_speaker_modal {
    width: 100%;

    .add_speaker_wrapper {
        @include row(space-between, flex-start, 10px);
        margin-top: 10px;

        .btn {
            flex: 1
        }

        .input_wrapper {
            flex: 2;
        }
    }

    .speakers_list {
        @include column(center, center, 0);
    }

    .speakers_dropdown__content__list__item > span {
        font-size: 1.1rem;
    }
}

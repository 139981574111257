@import 'styles/flex-mixins';
@import 'styles/media';

.balance_page {
    @include column(flex-start, flex-start, 4vmin);

    @include landscape-max-width {
        align-items: center;

        text-align: center;
    }
    padding: 6vmin 7vw;

    .page_title {
        font-family: MontserratBold, serif;
        font-size: clamp(28px, 9vw, 35px);
    }

    .page_container {
        @include row(space-between, flex-start, 5vmin);

        @include laptop-s-max-width {
            width: 100%;
        }

        @include landscape-max-width {
            @include column-reverse(center, flex-start, 5vmin);
        }

        width: 90%;

        .actions {
            @include column(flex-start, flex-start, 3vmin);
            @include landscape-max-width {
                @include column(center, center, 5vmin);
            }

            .tabs {
                @include row(flex-start, center, 20px);
                width: 100%;

                .tab {
                    @include column(center, center, 10px);

                    cursor: pointer;

                    width: 30%;
                    height: 100px;
                    padding: 10px;

                    color: var(--primary-02-dark);

                    background-color: var(--primary-02-light);
                    border-radius: var(--default-border-radius);

                    &--active {
                        color: var(--neutral-10);

                        background-color: var(--primary-01);
                    }

                    &__info {
                        font-size: clamp(10px, 3vmin, 14px);
                        text-align: center;
                    }

                    &__icon {
                        width: 25px;
                        height: 20px;
                    }
                }
            }

            .tip {
                max-width: 500px;

                font-size: clamp(1rem, 2.5vw, 1.15rem);
                line-height: 150%;
                color: var(--neutral-02);
            }
        }

        .page_info {
            @include column(flex-start, flex-start, 10px);

            @include landscape-max-width {
                max-width: none;
            }
            max-width: clamp(250px, 30%, 400px);

            color: var(--primary-01-dark);

            &__title {
                font-family: MontserratBold, serif;
                font-size: 1rem;
                line-height: 150%;
            }

            &__text {
                font-size: 0.85rem;
                line-height: 150%;
            }
        }
    }
}

@import 'styles/flex-mixins';
@import 'styles/media';

.receipt_form {
    @include column(flex-start, flex-start, 20px);

    width: 100%;
    max-width: 360px;

    .deposit_amount {
        width: 70%;
        padding-left: 50px;

        background: url('/assets/balance/ruble.svg') no-repeat 15px 12px var(--neutral-10);
    }

    .btn {
        width: 100%;
    }
}

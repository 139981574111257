@import 'styles/flex-mixins';
@import 'styles/generics';
@import 'styles/media';

.records__header {
    @include row(space-between, center, clamp(5px, 1.5vw, 10px));
    @include box-shadow-default;
    @include tablet-max-width {
        margin: 7px;
    }

    min-width: fit-content;
    min-height: var(--header-height);
    margin: var(--header-top-margin) clamp(10px, 1.5vw, 20px) 0;
    padding: 0 3%;

    background-color: var(--neutral-10);
    border-radius: 20px;

    .logo_link {
        @include row(flex-start, center, 0);
        @include landscape-max-width {
            width: 140px;
        }
        width: 190px;
        height: 90%;
    }

    .logo_image {
        align-self: center;

        width: 100%;
        max-height: 100%;
    }

    > .empty_block {
        @include laptop-s-max-width {
            display: none;
        }

        flex-grow: 1;

        max-width: 10%;
    }

    .balance {
        @include row(center, center, clamp(3px, 5%, 10px));

        &__value {
           font-family: MontserratBold, serif;
            color: var(--primary-01-dark);
            white-space: nowrap;
        }
    }

    > .balance {
        @include tablet-max-width {
            display: none;
        }
    }

    .user_info {
        @include mobile-xl-max-width {
            justify-content: end;
        }
        @include mobile-l-max-width {
            .arrow {
                display: none;
            }
        }
        @include mobile-m-max-width {
            .arrow {
                display: block;
            }
        }
    }
}

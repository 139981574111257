@import 'styles/flex-mixins';

.player {
    @include row(flex-start, center, 3%);

    .speed_dropdown {
        cursor: pointer;
        user-select: none;

        position: relative;

        width: min-content;

        text-align: center;

        .dropdown_menu {
            position: absolute;
            left: 0;

            width: 50px;
        }
    }

    .audio_current_time,
    .audio_duration {
        width: 72px;

        color: var(--primary-01-dark);
    }

    .audio_range {
        flex: 1;

        height: 0;

        border: 2px solid var(--primary-01-light);

        appearance: none !important;

        &::-webkit-slider-thumb {
            width: 20px;
            height: 20px;

            background: var(--primary-02);
            border-radius: 50%;

            appearance: none !important;
        }
    }
}

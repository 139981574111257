.default_checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.default_checkbox [type='checkbox'] {
    --webkit-appearance: none;
    cursor: pointer;

    display: inline-block;

    width: 1.5rem;
    height: 1.5rem;

    border: 0.15rem solid #c7cfda;
    border-radius: 0.25rem;
    outline: none;

    appearance: none;
}

.default_checkbox_input:checked {
    background-color: var(--primary-01);
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    border-color: transparent;
}

.default_checkbox_input:disabled {
    background-color: #ccd0d7;
}

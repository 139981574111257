@import 'styles/flex-mixins';
@import 'styles/media';
@import 'styles/generics';

.transcript_page .page_control {
    @include box-shadow-default;

    top: 0;

    background-color: var(--neutral-10);
    border-radius: var(--default-border-radius);

    transition: top 1s;

    .records__header {
        display: none;

        min-height: 40px;
        margin: 0;
        padding: 0 20px;

        background-color: var(--neutral-01);

        .logo_link {
            width: 120px;
            height: 100%;
        }

        .router_tabs a {
            border-radius: 0;
        }
    }

    .simple_navigation {
        display: none;
        align-items: center;

        max-width: 300px;

        .icon {
            cursor: pointer;

            padding-right: 24px;

            div {
                transform: rotate(90deg);

                filter: opacity(0.8);
            }
        }
    }

    > * {
        @include mobile-xl-max-width {
            width: 100%;
        }
    }

    .player {
        @include mobile-xl-max-width {
            flex-wrap: wrap;
            row-gap: 0.5em;

            .audio_range {
                order: 4;
            }
        }
        flex: 1;

        > * {
            margin-right: 24px;
        }

        .audio_range {
            height: 10px;
        }
    }

    .transcript_control {
        display: flex;
        flex-direction: column;

        .top_section,
        .bottom_section {
            @include row(flex-start, center, 4%);

            flex-wrap: wrap;

            padding: 15px;
        }

        .top_section {
            @include laptop-s-max-width {
                justify-content: space-between;

                .date_info {
                    order: 1;
                }

                .record_actions {
                    order: 2;
                }

                .player {
                    order: 3;

                    min-width: 100%;
                }
            }
            row-gap: 10px;
        }

        .bottom_section {
            padding: 5px 15px;

            background-color: var(--primary-01-light);

            > * {
                margin: 0;
                padding: 0 12px;
            }

            .actions_group {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                border-right: 1px solid var(--neutral-05);

                .btn {
                    width: 32px;
                    height: 30px;
                    padding: 4px;

                    svg {
                        fill: #544080;
                    }

                    &-disabled {
                        svg {
                            fill: #c5c5c5;
                        }
                    }
                }

                .btn.clean {
                    svg {
                        fill: none;
                    }
                }
            }

            .search_bar {
                height: 40px;
            }

            .not_found {
                .search_bar {
                    border-color: var(--service-02);
                }
            }

            .protocol_btn {
                flex-grow: 1;
                justify-content: flex-end;

                font-weight: 700;
            }
        }
    }

    .upload_date,
    .saving_date {
        font-size: 0.85em;
        color: var(--neutral-03);
    }

    .saving_date {
        min-width: 225px;
    }

    .date_info {
        @include column(flex-start, center, 0);
        padding: 5px 0;

        .recording_date {
            display: flex;

            margin-bottom: 8px;

            span {
                margin-left: 11px;

                font-family: MontserratBold, serif;
                color: var(--primary-01-dark);
            }
        }
    }

    .record_actions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        min-height: 65px;

        .download_info {
            margin-right: 15px;

            color: var(--neutral-02);
        }

        .download_text,
        .download_record,
        .delete_transcript {
            @include mobile-xl-max-width {
                min-width: fit-content;
            }

            width: 48px;
            height: 40px;
            margin-right: 15px;
            padding: 8px 12px;
        }

        .download_record_wrapper {
            position: relative;

            .dropdown_menu {
                min-width: 150px;

                box-shadow: 0 13px 25px var(--shadow-3);

                &__item {
                    @include row(flex-start, center, 0);
                    width: 100%;
                    height: 35px;
                    padding: 5px 10px;
                }
            }
        }
    }

    &--onTop {
        position: fixed;
        z-index: 100;
        top: 0;

        width: 100%;
        margin: 0;

        .date_info {
            display: none;
        }

        .records__header {
            display: flex;
        }

        .simple_navigation {
            display: flex;
        }

        .transcript_control {
            .top_section {
                padding: 0 124px;

                background-color: white;
            }

            .bottom_section {
                padding: 5px 124px;
            }
        }
    }
}
